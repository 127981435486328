$numberSize: 40px;

.accordion {
  &--closed {
    .accordion__content {
      display: none;
    }

    .accordion__title {
      border-bottom: none;
    }
  }

  &__line {
    width: 3px;
    margin-left: 18px;
    margin-right: 11px;
    background-color: $border;
    height: 100%;
  }

  &:last-child .accordion__line {
    background-color: transparent;
    margin-left: 0;
    margin-right: 0;
    width: $numberSize;
  }

  &__title {
    border-bottom: 1px solid $border;
    line-height: $numberSize;
  }

  &__number {
    margin-top: 2px;
    width: $numberSize;
    height: $numberSize;
    color: #fff;
    background-color: $primary;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: $numberSize;
  }
}
