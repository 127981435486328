.button {
  display: inline-block;
  min-width: 180px;
  font-size: 16px;
  padding: $spacing / 2;
  color: $white;
  font-weight: 500;
  border-radius: 42px;
  background-color: $accent;
  text-align: center;
  border:none;

  &--small {
    min-width: 140px;
    padding: $spacing / 3 $spacing;
  }
  &--x-small {
    font-size: 14px;
    padding: 1em;
    width: auto;
    min-width: auto;
    height: auto;
    white-space: nowrap;
  }

  &--block {
    display: block;
  }

  &--secondary {
    background-color: $primary;
  }

  &--orange {
    background-color: $orange;
  }

  &--tetriary {
    background-color: $background;
    color: $primary;

    &:hover,
    &:focus {
      color: $primary !important;
    }
  }

  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
  }

  &:active {
    transform: translateY(2px);
  }

  &--outlined {
    background-color: transparent;
    border: 1px solid $border;
    color: $black !important;
  }

  &--dashed {
    background-color: transparent;
    border: 1px dashed $border;
    color: $primary !important;
  }

  &--gray {
    background-color: $background;
    border: 1px solid $border;
    color: $black !important;
  }

  &--less-rounded {
    border-radius: 10px;
  }

  &--smaller {
    min-width: 0;
    padding: $spacing / 10 $spacing / 1.5;
    font-size: 14px;
  }

  &--vertical-spacing {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  &--any-width {
    width: auto;
  }

  &--fill-top {
    align-items: flex-start;
  }
  &--no-focus-outline:focus {
    outline: none;
  }
  &--pointer {
    cursor: pointer;
  }
  &--padding-zero {
    padding: 0;
  }
  &--pointer{
    cursor:pointer;
  }
  &--facebook-connect{
    font-family: Poppins, sans-serif;
    background:#1877f2;
    color:#fff;
    border:none;
    border-radius: 0.5em;
    padding: .5em 1em;
    margin:1em 0;
    cursor:pointer;
    &:hover{
      background:#3a84e6;
    }
    &:active{
      background:#4267b2;
    }
    &:visited,&:active, &:focus{
      outline:none;
    }
    i{
      padding-right: .5em;
    }
  }
}

.btn-link {
  border: none;
  padding: 0;
  display: inline-block;
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  box-sizing: content-box;
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em;
  /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto;
  /* Chrome ignores auto, but here for completeness */
}
.btn-link:hover{
  text-decoration: underline;
}
.btn-link:active,.btn-link:focus{
  border:none;
  outline:none;
}

button.button {
  // border: none
}
