// Colors
$primary: #48399e;
$primaryLight: #7661ed;
$accent: #f52447;
$black: #1b1d1e;
$textGray: #676f73;
$textMuted: #9eaab1;
$gray: #ededf4;
$lightGray: #d1d1d1;
$border: #e0dfef;
$background: #f7f7fd;
$white: #ffffff;
$zmash-aqua: #31a9b8;

// Layout
$spacing: 30px;
$radius: 60px;

// Fonts
$fontBody: "Roboto", sans-serif;
$fontHeadline: "Poppins", sans-serif;

// Bootstrap
$theme-colors: (
  "primary": $primary,
  "black": $black,
  "text-gray": $textGray,
  "dark-gray": $textGray,
  "gray": $textMuted,
  "light-gray": $border,
  "bg-gray": $background
);

$body-bg: map-get($theme-colors, "bg-gray");
$body-color: map-get($theme-colors, "text-gray");
$text-muted: map-get($theme-colors, "gray");
$grid-gutter-width: $spacing;
