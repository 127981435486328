.sidebar {
  display: block;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  background: $primary;
  height: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow-y: auto;

  @include media-breakpoint-down(lg) {
    position: fixed;
    transform: translateX(100%);
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    box-shadow: none;

    &--visible {
      opacity: 1;
      transform: none;
    }
  }
  .logo-container {
    display: flex;
    justify-content: center;
    a {
      display: block;
      img {
        width: auto;
        height: 2.5em;
      }
    }
  }

  &__bottom {
    background-color: $primary;
    padding: $spacing;
  }
  &__label-container {}

  &__link {
    font-weight: 400;
    font-family: $fontHeadline;
    display: flex;
    align-items: center;
    height: 50px;
    text-decoration: none;
    color: $white;
    padding-left: $spacing;
    padding-right: $spacing;
    border-left: 4px solid $primary;
    transition: color 100ms ease, border 100ms ease;

    .label {
      position: relative;
      .jewel {
        position: absolute;
        font-size: .55em;
        position: absolute;
        right: -55%;
        top: -.4em;
        &--text {
          line-height: 1em;
          color: $primary;
          padding: .4em .6em;
          border-radius: 1em;
          background-color: $white;
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: $lightGray;
      border-left: 4px solid $white;
    }

    .svg-inline--fa {
      font-size: 24px;
      margin-right: $spacing / 2;
    }

    &--active {
      color: $primary;
      border-left: 4px solid $white;
      padding-left: $spacing - 4px;
      background: linear-gradient( to right,
      $background 0%,
      rgba($background, 0) 100%);

      .svg-inline--fa {
        font-weight: 700;
      }
    }
  }
}
