.card {
  background-color: $white;
  border-radius: 10px;
  width: 100%;
  display: block;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  &--project {
    flex-wrap: no-wrap;
  }
  &--elevated {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  &--fixed-width {
    max-width: 210px;
    min-width: 210px;
  }

  &--fill {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
  }

  &--fill-top {
    align-items: flex-start;
  }
  &__table{
    overflow:hidden;
  }
  &__content {
    padding: $spacing / 2 $spacing / 1.5;
    width: 100%;
    &.metric {
      z-index: 1;
      padding: 2em 1em;
      min-height: 10em;
    }
    &.project {
      z-index: 1;
      padding: 2em;
      min-height: 10em;
      
    }
  }

  &__content-large {
    padding: $spacing * 1.5 $spacing;
  }

  &__h-content {
    padding: $spacing / 2 $spacing;
  }

  &__even-content {
    padding: $spacing / 2;
  }

  &__even-content-large {
    padding: $spacing;
  }
  &--any-width {
    width: auto;
  }

  &--vertical {
    padding: $spacing / 1.5 0;

    svg {
      width: 100%;
    }
  }
  &--outlined {
    box-shadow: none;
    border: 1px solid $border;
    background-color: transparent;
  }

  &--dashed {
    box-shadow: none;
    border: 1px dashed $border;
    background-color: transparent;
  }
}

@include media-breakpoint-down(lg) {
  .card {
    &--project {
      flex-wrap: wrap;
    }
    &__right {
      width: 100%;
    }
  }
}
