html,
body {
  font-family: $fontBody;
  font-size: 16px;
  color: $textGray;
  background-color: $background;
  width: 100%;
  height: 100%;
  position: fixed;
  line-height: 30px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $fontHeadline;
  color: $black;
  font-weight:400;
  line-height:1.25em;
}

h1 {
  font-size: 3rem;
  margin-bottom: $spacing / 1.5;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  color: $black;
  margin-bottom: $spacing / 2;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: $spacing / 4;
}

.container {
  padding-left: $spacing;
  padding-right: $spacing;

  @media (min-width: 1200px) {
    max-width: 1170px;
  }
}

.content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.wrapper {
  display: flex;
  height: 100%;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.small-gutters {
  margin-right: -$spacing/4;
  margin-left: -$spacing/4;

  > .col,
  > [class*="col-"] {
    padding-right: $spacing/4;
    padding-left: $spacing/4;
  }
}

.smaller-gutters {
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
