.metric {
  &__stats {
    min-width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >.metric__title {
      font-size: 2em;
    }
  }
  &__icon-container {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9em;
    opacity: .4;
    >svg {
      vertical-align: 0;
    }
  }

  &__title {
    color: $white;
    font-size: 1.5rem;
    font-weight: 400;

    &--large {
      font-size: 1.5rem;
    }
  }
  &__description {
    color: $white;
    font-size: .8em;
    line-height: 1.5em;
  }
  &--fill {
    align-items: flex-start;
  }
}

.brand-metric {
  font-size: 1.5em;
  .metric__title {
    font-size: 2.5rem
  }
  .icon-aligner {
    display: inline-block;

    .icon-bubble {
      display: flex;
      background: #fff9;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      margin-bottom: 1em;
      font-size: 1.5em;
      justify-content: center;
      align-items: center;
      .icon-wrapper {
        display: block;
      }
    }
  }
}
