.table {
  width: 100%;
  overflow: auto;
  &.table-insights {
    font-size: .85em;
    td,
    thead th {
      padding: $spacing / 5 $spacing;
      ;
    }
    tr td:last-child {
      width: 1%;
      white-space: nowrap;
      text-align: right;
    }
  }

  td {
    padding: $spacing / 2 $spacing / 2;
    vertical-align: middle;
    word-break: break-word;
    button.btn-link{
      height:auto;
    }

    &.comment, &.hideLead {
      position: relative;
      width: 100px;
      text-align: center;

      >button {
        display: inline-block;
        width: 2em;
        border-radius: 50%;
        height: 2em;
        transition: all .1s ease;
        background: transparent;
        text-align: center;
        cursor:pointer;
        &:active{
          color:#fff !important;
          background: #48399e;
        }
        &.comment-highlight {
          background: #48399e;
          color: #fff !important;
        }
      }
    }
    .show-on-hover {
      opacity: .3;
      transition: opacity .8s ease;
      &:hover {
        opacity: 1;
      }
    }
  }

  thead {
    tr{
      border-radius: 10px 10px 0 0;
    }
    th {
      padding: $spacing / 2 $spacing;
      vertical-align: middle;
      border-bottom: 1px solid $border;
      text-transform: uppercase;
      font-weight: 500;
      background: $primary;
      color: $white
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: transparent;
      }

      &:nth-of-type(even) {
        background-color: rgba($background, 0.8);
      }
    }
  }

  &__checkbox {
    width: 50px;
    padding-right: 0 !important;
  }

  &__sortable {
    color: $white;

    &--active,
    &:hover {
      color: white;
    }
  }
  &__not-sortable {
    color: whitey;

    &--active,
    &:hover {
      color: $white;
      text-decoration: none;
      cursor: default;
    }
  }
  @include media-breakpoint-down(md) {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    td {
      padding: $spacing / 4 $spacing / 2;

    }


    .table__mobile-heading {
      width: 100%;
      padding-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}

.table-container {
  max-height: 500px;
  overflow-y: auto;
}
