.separator-h {
  width: 100%;
  height: 1px;
  background-color: $border;
  display: block;
}

.separator {
  height: 20px;
  width: 1px;
  background-color: $border;
  margin-left: $spacing / 2;
  margin-right: $spacing / 2;
  display: block;

  &--large {
    height: 60px;
    margin-left: $spacing / 1.5;
    margin-right: $spacing / 1.5;
  }

  &--full {
    height: 100%;
  }
}

.with-separator{
  border-right:1px solid $border;
  // padding-right: $spacing;
  // margin-right: $spacing / 2;
}