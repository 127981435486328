.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding: 0 !important;
    top: 65px;
    height: auto;

    .modal__content {
      border-radius: 0 !important;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
  }

  &--active {
    opacity: 1;
    pointer-events: auto;

    .modal__content {
      transform: none;
      opacity: 1;
    }
  }
  &--personal-data-form{
    z-index: 1001;
  }
  &__content {
    max-width: 500px;
    position: relative;
    overflow-y: auto;
    max-height: 90vh;
    transform: translateY(100px);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 0;

    &--large {
      max-width: 800px;
    }
    &--x-large {
      // min-width: 960px;
      max-width: 960px;
    }
    &--form {
      min-height: 600px;
      max-width: 800px;
    }
  }
}
