.pagination {
  vertical-align: middle;
  font-weight: bold;

  &__link {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;

    &--active {
      background-color: $primary;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 20px;
      width: 24px;
      height: 24px;
      padding: 0;
      text-align: center;
      line-height: 24px;
    }
  }
}
