.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  z-index: 1
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper.login {
  background-color: #000;
}

.loginbg {
  /*background-image: url(https://sharpmind-cdn.ams3.digitaloceanspaces.com/zmash/2018/08/pineapple-supply-co-56454-unsplash.jpg);*/
  /*background-size: cover;*/
  /*background-position: center;*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .6;
  filter: blur(5px);
  z-index: -1
}

.section.auth {
  background-color: #ffffffcc;
  width: 20em;
  text-align: center;
  padding: 2em;
  border-radius: 1em;
}

.section.auth button {
  appearance: none !important;
  -webkit-appearance: none !important;
  border: none;
}

.section.auth input {
  appearance: none !important;
  -webkit-appearance: none !important;
  border: 2px solid #aaa;
  border-radius: .5em;
  margin-bottom: 1em;
  padding: .5em 1em;
}

.section.auth button:focus {
  appearance: none !important;
  -webkit-appearance: none !important;
  border: none;
  outline: none;
}

.error {
  margin-top: 1em;
  color: #f52446;
  border: #f52446 2px solid;
  border-radius: 1em;
}

#zmashbot-loading {
  -webkit-animation: 1.5s zmashbot-loading cubic-bezier(0.075, 0.82, 0.165, 1.1) forwards;
  animation: 1.5s zmashbot-loading cubic-bezier(0.075, 0.82, 0.165, 1.1) forwards;
  transform: scale(3)
}

#zmashbot-loading rect {
  -webkit-transition: 0.3s all linear;
  transition: 0.3s all linear;
}

.fill-primary {
  fill: #48399e;
}

.loading-wrapper {
  height: 100%;
  width: 90%;
  display: block;
}
.save-screen-modal .loading-wrapper {
  width: auto;
}

.loading-wrapper>div {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
