.h-spacing {
  width: $spacing;
  flex-shrink: 0;

  &--medium {
    width: $spacing/2;
  }

  &--small {
    width: $spacing/3;
  }

  &--extra-small {
    width: $spacing/6;
  }
}

.c-spacing {
  padding-left: $spacing;
  padding-right: $spacing;

  &--medium {
    padding-left: $spacing / 1.5;
    padding-right: $spacing / 1.5;
  }
}

.spacing {
  height: $spacing;

  &--large {
    height: $spacing * 1.5;
  }

  &--extra-large {
    height: $spacing * 2;
  }

  &--medium {
    height: $spacing / 1.5;
  }

  &--small {
    height: $spacing / 2;
  }

  &--small-half {
    height: $spacing / 4;
  }

  &--extra-small {
    height: $spacing / 6;
  }
}