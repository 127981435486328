.search {
  position: relative;

  &__icon {
    color: $textMuted;
    position: absolute;
    left: 25px;
    top: 12px;
  }

  &__field {
    background: #ffffff;
    border: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
    border-radius: 22px;
    height: 42px;
    color: $textGray;
    padding-left: 55px;
    padding-right: 30px;
    width: 260px;
    max-width: 100%;

    &:focus {
      outline: none;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      color: $primary;

      & ~ .search__icon {
        color: $primary;
      }
    }
  }
}
