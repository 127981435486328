.mw-paragraph {
  max-width: 500px;
  color:$black;
  line-height:1.35em;
}

.full-width {
  width: 100%;
}

.rounded {
  border-radius: 10px;
  overflow: hidden;
}

.text-large {
  font-size: 1.125rem;
}

.text-extra-large {
  font-size: 1.25rem;
}

.text-green {
  color: $green;
}

.text-orange {
  color: $orange;
}

.text-primary-light {
  color: $primaryLight;
}

.text-accent {
  color: $accent;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black;
}

.text-muted {
  color: $textMuted;
}

.font-weight-medium {
  font-weight: 500;
}

.font-headline {
  font-family: $fontHeadline;
}

.no-underline {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.text-small {
  font-size: 0.875rem;
}

.text-smaller {
  font-size: 0.75rem;
}

.circle {
  border-radius: 50%;
  text-align: center;
  display: inline-block;

  &--40 {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  &--32 {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
}

.center-in-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-height-in-column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bg-light-green {
  background-color: rgba($green, 0.1);
}

.bg-light-accent {
  background-color: rgba($accent, 0.1);
}

.bg-light-muted {
  background-color: rgba($textMuted, 0.1);
}

.bg-light-primary {
  background-color: rgba($primary, 0.1);
}

.active-in-list {
  background-color: rgba($primary, 0.1);
  color: $primary;
  margin-left: 10px;
  // margin-right: -10px;
  // padding-left: 20px;
  // padding-right: 10px;
  border-radius: 4px;
}

.absolute-right {
  position: absolute;
  right: 0;
}
.flex-grow{
  flex-grow:1;
}
.justify-content-right{
  justify-content:flex-end;
}