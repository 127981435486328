/*!
 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
@import url("https://fonts.googleapis.com/css?family=Poppins:500,700|Roboto:400,500,700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(27, 29, 30, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #676f73;
  text-align: left;
  background-color: #f7f7fd; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #48399e;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #2e2566;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #9eaab1;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap Grid v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #48399e; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #372b79; }

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #ffffff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #ffffff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #ffffff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #ffffff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #1d2124; }

.badge-black {
  color: #ffffff;
  background-color: #1b1d1e; }
  .badge-black[href]:hover, .badge-black[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #030303; }

.badge-text-gray {
  color: #ffffff;
  background-color: #676f73; }
  .badge-text-gray[href]:hover, .badge-text-gray[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #4f5558; }

.badge-dark-gray {
  color: #ffffff;
  background-color: #676f73; }
  .badge-dark-gray[href]:hover, .badge-dark-gray[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #4f5558; }

.badge-gray {
  color: #212529;
  background-color: #9eaab1; }
  .badge-gray[href]:hover, .badge-gray[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #82919a; }

.badge-light-gray {
  color: #212529;
  background-color: #e0dfef; }
  .badge-light-gray[href]:hover, .badge-light-gray[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #bfbdde; }

.badge-bg-gray {
  color: #212529;
  background-color: #f7f7fd; }
  .badge-bg-gray[href]:hover, .badge-bg-gray[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #cecef3; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #48399e !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #372b79 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-black {
  background-color: #1b1d1e !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #030303 !important; }

.bg-text-gray {
  background-color: #676f73 !important; }

a.bg-text-gray:hover, a.bg-text-gray:focus,
button.bg-text-gray:hover,
button.bg-text-gray:focus {
  background-color: #4f5558 !important; }

.bg-dark-gray {
  background-color: #676f73 !important; }

a.bg-dark-gray:hover, a.bg-dark-gray:focus,
button.bg-dark-gray:hover,
button.bg-dark-gray:focus {
  background-color: #4f5558 !important; }

.bg-gray {
  background-color: #9eaab1 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #82919a !important; }

.bg-light-gray {
  background-color: #e0dfef !important; }

a.bg-light-gray:hover, a.bg-light-gray:focus,
button.bg-light-gray:hover,
button.bg-light-gray:focus {
  background-color: #bfbdde !important; }

.bg-bg-gray {
  background-color: #f7f7fd !important; }

a.bg-bg-gray:hover, a.bg-bg-gray:focus,
button.bg-bg-gray:hover,
button.bg-bg-gray:focus {
  background-color: #cecef3 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #48399e !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-black {
  border-color: #1b1d1e !important; }

.border-text-gray {
  border-color: #676f73 !important; }

.border-dark-gray {
  border-color: #676f73 !important; }

.border-gray {
  border-color: #9eaab1 !important; }

.border-light-gray {
  border-color: #e0dfef !important; }

.border-bg-gray {
  border-color: #f7f7fd !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(27, 29, 30, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(27, 29, 30, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(27, 29, 30, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #48399e !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #372b79 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-black {
  color: #1b1d1e !important; }

a.text-black:hover, a.text-black:focus {
  color: #030303 !important; }

.text-text-gray {
  color: #676f73 !important; }

a.text-text-gray:hover, a.text-text-gray:focus {
  color: #4f5558 !important; }

.text-dark-gray {
  color: #676f73 !important; }

a.text-dark-gray:hover, a.text-dark-gray:focus {
  color: #4f5558 !important; }

.text-gray {
  color: #9eaab1 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #82919a !important; }

.text-light-gray {
  color: #e0dfef !important; }

a.text-light-gray:hover, a.text-light-gray:focus {
  color: #bfbdde !important; }

.text-bg-gray {
  color: #f7f7fd !important; }

a.text-bg-gray:hover, a.text-bg-gray:focus {
  color: #cecef3 !important; }

.text-body {
  color: #676f73 !important; }

.text-muted {
  color: #9eaab1 !important; }

.text-black-50 {
  color: rgba(27, 29, 30, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 57, 158, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #48399e;
  border-color: #48399e; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #3b2f82;
    border-color: #372b79; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 57, 158, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #48399e;
    border-color: #48399e; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #372b79;
    border-color: #33286f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 57, 158, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-black {
  color: #ffffff;
  background-color: #1b1d1e;
  border-color: #1b1d1e; }
  .btn-black:hover {
    color: #ffffff;
    background-color: #090a0a;
    border-color: #030303; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(27, 29, 30, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #ffffff;
    background-color: #1b1d1e;
    border-color: #1b1d1e; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #ffffff;
    background-color: #030303;
    border-color: black; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(27, 29, 30, 0.5); }

.btn-text-gray {
  color: #ffffff;
  background-color: #676f73;
  border-color: #676f73; }
  .btn-text-gray:hover {
    color: #ffffff;
    background-color: #555c5f;
    border-color: #4f5558; }
  .btn-text-gray:focus, .btn-text-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }
  .btn-text-gray.disabled, .btn-text-gray:disabled {
    color: #ffffff;
    background-color: #676f73;
    border-color: #676f73; }
  .btn-text-gray:not(:disabled):not(.disabled):active, .btn-text-gray:not(:disabled):not(.disabled).active,
  .show > .btn-text-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #4f5558;
    border-color: #494f51; }
    .btn-text-gray:not(:disabled):not(.disabled):active:focus, .btn-text-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-text-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }

.btn-dark-gray {
  color: #ffffff;
  background-color: #676f73;
  border-color: #676f73; }
  .btn-dark-gray:hover {
    color: #ffffff;
    background-color: #555c5f;
    border-color: #4f5558; }
  .btn-dark-gray:focus, .btn-dark-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }
  .btn-dark-gray.disabled, .btn-dark-gray:disabled {
    color: #ffffff;
    background-color: #676f73;
    border-color: #676f73; }
  .btn-dark-gray:not(:disabled):not(.disabled):active, .btn-dark-gray:not(:disabled):not(.disabled).active,
  .show > .btn-dark-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #4f5558;
    border-color: #494f51; }
    .btn-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-dark-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }

.btn-gray {
  color: #212529;
  background-color: #9eaab1;
  border-color: #9eaab1; }
  .btn-gray:hover {
    color: #ffffff;
    background-color: #8997a0;
    border-color: #82919a; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(158, 170, 177, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #212529;
    background-color: #9eaab1;
    border-color: #9eaab1; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #82919a;
    border-color: #7b8b95; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(158, 170, 177, 0.5); }

.btn-light-gray {
  color: #212529;
  background-color: #e0dfef;
  border-color: #e0dfef; }
  .btn-light-gray:hover {
    color: #212529;
    background-color: #c7c6e2;
    border-color: #bfbdde; }
  .btn-light-gray:focus, .btn-light-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 223, 239, 0.5); }
  .btn-light-gray.disabled, .btn-light-gray:disabled {
    color: #212529;
    background-color: #e0dfef;
    border-color: #e0dfef; }
  .btn-light-gray:not(:disabled):not(.disabled):active, .btn-light-gray:not(:disabled):not(.disabled).active,
  .show > .btn-light-gray.dropdown-toggle {
    color: #212529;
    background-color: #bfbdde;
    border-color: #b7b5da; }
    .btn-light-gray:not(:disabled):not(.disabled):active:focus, .btn-light-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 223, 239, 0.5); }

.btn-bg-gray {
  color: #212529;
  background-color: #f7f7fd;
  border-color: #f7f7fd; }
  .btn-bg-gray:hover {
    color: #212529;
    background-color: #d8d8f5;
    border-color: #cecef3; }
  .btn-bg-gray:focus, .btn-bg-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 247, 253, 0.5); }
  .btn-bg-gray.disabled, .btn-bg-gray:disabled {
    color: #212529;
    background-color: #f7f7fd;
    border-color: #f7f7fd; }
  .btn-bg-gray:not(:disabled):not(.disabled):active, .btn-bg-gray:not(:disabled):not(.disabled).active,
  .show > .btn-bg-gray.dropdown-toggle {
    color: #212529;
    background-color: #cecef3;
    border-color: #c4c4f0; }
    .btn-bg-gray:not(:disabled):not(.disabled):active:focus, .btn-bg-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-bg-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 247, 253, 0.5); }

.btn-outline-primary {
  color: #48399e;
  background-color: transparent;
  background-image: none;
  border-color: #48399e; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #48399e;
    border-color: #48399e; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 57, 158, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #48399e;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #48399e;
    border-color: #48399e; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 57, 158, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-black {
  color: #1b1d1e;
  background-color: transparent;
  background-image: none;
  border-color: #1b1d1e; }
  .btn-outline-black:hover {
    color: #ffffff;
    background-color: #1b1d1e;
    border-color: #1b1d1e; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(27, 29, 30, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #1b1d1e;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #ffffff;
    background-color: #1b1d1e;
    border-color: #1b1d1e; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(27, 29, 30, 0.5); }

.btn-outline-text-gray {
  color: #676f73;
  background-color: transparent;
  background-image: none;
  border-color: #676f73; }
  .btn-outline-text-gray:hover {
    color: #ffffff;
    background-color: #676f73;
    border-color: #676f73; }
  .btn-outline-text-gray:focus, .btn-outline-text-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }
  .btn-outline-text-gray.disabled, .btn-outline-text-gray:disabled {
    color: #676f73;
    background-color: transparent; }
  .btn-outline-text-gray:not(:disabled):not(.disabled):active, .btn-outline-text-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-text-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #676f73;
    border-color: #676f73; }
    .btn-outline-text-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-text-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-text-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }

.btn-outline-dark-gray {
  color: #676f73;
  background-color: transparent;
  background-image: none;
  border-color: #676f73; }
  .btn-outline-dark-gray:hover {
    color: #ffffff;
    background-color: #676f73;
    border-color: #676f73; }
  .btn-outline-dark-gray:focus, .btn-outline-dark-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }
  .btn-outline-dark-gray.disabled, .btn-outline-dark-gray:disabled {
    color: #676f73;
    background-color: transparent; }
  .btn-outline-dark-gray:not(:disabled):not(.disabled):active, .btn-outline-dark-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-gray.dropdown-toggle {
    color: #ffffff;
    background-color: #676f73;
    border-color: #676f73; }
    .btn-outline-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 111, 115, 0.5); }

.btn-outline-gray {
  color: #9eaab1;
  background-color: transparent;
  background-image: none;
  border-color: #9eaab1; }
  .btn-outline-gray:hover {
    color: #212529;
    background-color: #9eaab1;
    border-color: #9eaab1; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(158, 170, 177, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #9eaab1;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #212529;
    background-color: #9eaab1;
    border-color: #9eaab1; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(158, 170, 177, 0.5); }

.btn-outline-light-gray {
  color: #e0dfef;
  background-color: transparent;
  background-image: none;
  border-color: #e0dfef; }
  .btn-outline-light-gray:hover {
    color: #212529;
    background-color: #e0dfef;
    border-color: #e0dfef; }
  .btn-outline-light-gray:focus, .btn-outline-light-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 223, 239, 0.5); }
  .btn-outline-light-gray.disabled, .btn-outline-light-gray:disabled {
    color: #e0dfef;
    background-color: transparent; }
  .btn-outline-light-gray:not(:disabled):not(.disabled):active, .btn-outline-light-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-gray.dropdown-toggle {
    color: #212529;
    background-color: #e0dfef;
    border-color: #e0dfef; }
    .btn-outline-light-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-light-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 223, 239, 0.5); }

.btn-outline-bg-gray {
  color: #f7f7fd;
  background-color: transparent;
  background-image: none;
  border-color: #f7f7fd; }
  .btn-outline-bg-gray:hover {
    color: #212529;
    background-color: #f7f7fd;
    border-color: #f7f7fd; }
  .btn-outline-bg-gray:focus, .btn-outline-bg-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 247, 253, 0.5); }
  .btn-outline-bg-gray.disabled, .btn-outline-bg-gray:disabled {
    color: #f7f7fd;
    background-color: transparent; }
  .btn-outline-bg-gray:not(:disabled):not(.disabled):active, .btn-outline-bg-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-bg-gray.dropdown-toggle {
    color: #212529;
    background-color: #f7f7fd;
    border-color: #f7f7fd; }
    .btn-outline-bg-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-bg-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-bg-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 247, 253, 0.5); }

.btn-link {
  font-weight: 400;
  color: #48399e;
  background-color: transparent; }
  .btn-link:hover {
    color: #2e2566;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.accordion--closed .accordion__content {
  display: none; }

.accordion--closed .accordion__title {
  border-bottom: none; }

.accordion__line {
  width: 3px;
  margin-left: 18px;
  margin-right: 11px;
  background-color: #e0dfef;
  height: 100%; }

.accordion:last-child .accordion__line {
  background-color: transparent;
  margin-left: 0;
  margin-right: 0;
  width: 40px; }

.accordion__title {
  border-bottom: 1px solid #e0dfef;
  line-height: 40px; }

.accordion__number {
  margin-top: 2px;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #48399e;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 40px; }

.card {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  display: block;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03); }
  .card--project {
    flex-wrap: no-wrap; }
  .card--elevated {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); }
  .card--fixed-width {
    max-width: 210px;
    min-width: 210px; }
  .card--fill {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative; }
  .card--fill-top {
    align-items: flex-start; }
  .card__table {
    overflow: hidden; }
  .card__content {
    padding: 15px 20px;
    width: 100%; }
    .card__content.metric {
      z-index: 1;
      padding: 2em 1em;
      min-height: 10em; }
    .card__content.project {
      z-index: 1;
      padding: 2em;
      min-height: 10em; }
  .card__content-large {
    padding: 45px 30px; }
  .card__h-content {
    padding: 15px 30px; }
  .card__even-content {
    padding: 15px; }
  .card__even-content-large {
    padding: 30px; }
  .card--any-width {
    width: auto; }
  .card--vertical {
    padding: 20px 0; }
    .card--vertical svg {
      width: 100%; }
  .card--outlined {
    box-shadow: none;
    border: 1px solid #e0dfef;
    background-color: transparent; }
  .card--dashed {
    box-shadow: none;
    border: 1px dashed #e0dfef;
    background-color: transparent; }

@media (max-width: 1199.98px) {
  .card--project {
    flex-wrap: wrap; }
  .card__right {
    width: 100%; } }

.button {
  display: inline-block;
  min-width: 180px;
  font-size: 16px;
  padding: 15px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 42px;
  background-color: #f52447;
  text-align: center;
  border: none; }
  .button--small {
    min-width: 140px;
    padding: 10px 30px; }
  .button--x-small {
    font-size: 14px;
    padding: 1em;
    width: auto;
    min-width: auto;
    height: auto;
    white-space: nowrap; }
  .button--block {
    display: block; }
  .button--secondary {
    background-color: #48399e; }
  .button--orange {
    background-color: #fd7e14; }
  .button--tetriary {
    background-color: #f7f7fd;
    color: #48399e; }
    .button--tetriary:hover, .button--tetriary:focus {
      color: #48399e !important; }
  .button:hover, .button:focus {
    color: #fff;
    text-decoration: none;
    opacity: 0.8; }
  .button:active {
    transform: translateY(2px); }
  .button--outlined {
    background-color: transparent;
    border: 1px solid #e0dfef;
    color: #1b1d1e !important; }
  .button--dashed {
    background-color: transparent;
    border: 1px dashed #e0dfef;
    color: #48399e !important; }
  .button--gray {
    background-color: #f7f7fd;
    border: 1px solid #e0dfef;
    color: #1b1d1e !important; }
  .button--less-rounded {
    border-radius: 10px; }
  .button--smaller {
    min-width: 0;
    padding: 3px 20px;
    font-size: 14px; }
  .button--vertical-spacing {
    margin-top: 2px;
    margin-bottom: 2px; }
  .button--any-width {
    width: auto; }
  .button--fill-top {
    align-items: flex-start; }
  .button--no-focus-outline:focus {
    outline: none; }
  .button--pointer {
    cursor: pointer; }
  .button--padding-zero {
    padding: 0; }
  .button--pointer {
    cursor: pointer; }
  .button--facebook-connect {
    font-family: Poppins, sans-serif;
    background: #1877f2;
    color: #fff;
    border: none;
    border-radius: 0.5em;
    padding: .5em 1em;
    margin: 1em 0;
    cursor: pointer; }
    .button--facebook-connect:hover {
      background: #3a84e6; }
    .button--facebook-connect:active {
      background: #4267b2; }
    .button--facebook-connect:visited, .button--facebook-connect:active, .button--facebook-connect:focus {
      outline: none; }
    .button--facebook-connect i {
      padding-right: .5em; }

.btn-link {
  border: none;
  padding: 0;
  display: inline-block;
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  box-sizing: content-box;
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em;
  /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto;
  /* Chrome ignores auto, but here for completeness */ }

.btn-link:hover {
  text-decoration: underline; }

.btn-link:active, .btn-link:focus {
  border: none;
  outline: none; }

.sidebar {
  display: block;
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  background: #48399e;
  height: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow-y: auto; }
  @media (max-width: 1199.98px) {
    .sidebar {
      position: fixed;
      transform: translateX(100%);
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      width: 100%;
      box-shadow: none; }
      .sidebar--visible {
        opacity: 1;
        transform: none; } }
  .sidebar .logo-container {
    display: flex;
    justify-content: center; }
    .sidebar .logo-container a {
      display: block; }
      .sidebar .logo-container a img {
        width: auto;
        height: 2.5em; }
  .sidebar__bottom {
    background-color: #48399e;
    padding: 30px; }
  .sidebar__link {
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    height: 50px;
    text-decoration: none;
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
    border-left: 4px solid #48399e;
    transition: color 100ms ease, border 100ms ease; }
    .sidebar__link .label {
      position: relative; }
      .sidebar__link .label .jewel {
        position: absolute;
        font-size: .55em;
        position: absolute;
        right: -55%;
        top: -.4em; }
        .sidebar__link .label .jewel--text {
          line-height: 1em;
          color: #48399e;
          padding: .4em .6em;
          border-radius: 1em;
          background-color: #ffffff; }
    .sidebar__link:hover {
      text-decoration: none;
      color: #d1d1d1;
      border-left: 4px solid #ffffff; }
    .sidebar__link .svg-inline--fa {
      font-size: 24px;
      margin-right: 15px; }
    .sidebar__link--active {
      color: #48399e;
      border-left: 4px solid #ffffff;
      padding-left: 26px;
      background: linear-gradient(to right, #f7f7fd 0%, rgba(247, 247, 253, 0) 100%); }
      .sidebar__link--active .svg-inline--fa {
        font-weight: 700; }

.toolbar {
  width: 100%;
  display: flex;
  justify-content: flex-end; }
  .toolbar__bar {
    background: #ffffff;
    display: flex;
    min-height: 50px;
    border-radius: 60px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 12px; }
  .toolbar__date-picker .react-datepicker {
    box-shadow: 2px 3px 7px 2px #ccc; }
    .toolbar__date-picker .react-datepicker__input-container input {
      margin-left: 15px;
      text-align: center;
      border-radius: .5em;
      border: 2px solid #ddd; }
    .toolbar__date-picker .react-datepicker__input-container input:focus {
      outline: none;
      border-color: #aaa; }
  .toolbar__date-picker .react-datepicker__day--selected,
  .toolbar__date-picker .react-datepicker__day--in-selecting-range,
  .toolbar__date-picker .react-datepicker__day--in-range,
  .toolbar__date-picker .react-datepicker__month-text--selected,
  .toolbar__date-picker .react-datepicker__month-text--in-selecting-range,
  .toolbar__date-picker .react-datepicker__month-text--in-range,
  .toolbar__date-picker .react-datepicker__quarter-text--selected,
  .toolbar__date-picker .react-datepicker__quarter-text--in-selecting-range,
  .toolbar__date-picker .react-datepicker__quarter-text--in-range {
    background-color: #7661ed; }
  .toolbar i {
    color: #1b1d1e; }
  .toolbar__logo {
    max-height: 30px; }

.separator-h {
  width: 100%;
  height: 1px;
  background-color: #e0dfef;
  display: block; }

.separator {
  height: 20px;
  width: 1px;
  background-color: #e0dfef;
  margin-left: 15px;
  margin-right: 15px;
  display: block; }
  .separator--large {
    height: 60px;
    margin-left: 20px;
    margin-right: 20px; }
  .separator--full {
    height: 100%; }

.with-separator {
  border-right: 1px solid #e0dfef; }

.search {
  position: relative; }
  .search__icon {
    color: #9eaab1;
    position: absolute;
    left: 25px;
    top: 12px; }
  .search__field {
    background: #ffffff;
    border: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
    border-radius: 22px;
    height: 42px;
    color: #676f73;
    padding-left: 55px;
    padding-right: 30px;
    width: 260px;
    max-width: 100%; }
    .search__field:focus {
      outline: none;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      color: #48399e; }
      .search__field:focus ~ .search__icon {
        color: #48399e; }

.pagination {
  vertical-align: middle;
  font-weight: bold; }
  .pagination__link {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px; }
    .pagination__link--active {
      background-color: #48399e;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 20px;
      width: 24px;
      height: 24px;
      padding: 0;
      text-align: center;
      line-height: 24px; }

.table {
  width: 100%;
  overflow: auto; }
  .table.table-insights {
    font-size: .85em; }
    .table.table-insights td,
    .table.table-insights thead th {
      padding: 6px 30px; }
    .table.table-insights tr td:last-child {
      width: 1%;
      white-space: nowrap;
      text-align: right; }
  .table td {
    padding: 15px 15px;
    vertical-align: middle;
    word-break: break-word; }
    .table td button.btn-link {
      height: auto; }
    .table td.comment, .table td.hideLead {
      position: relative;
      width: 100px;
      text-align: center; }
      .table td.comment > button, .table td.hideLead > button {
        display: inline-block;
        width: 2em;
        border-radius: 50%;
        height: 2em;
        transition: all .1s ease;
        background: transparent;
        text-align: center;
        cursor: pointer; }
        .table td.comment > button:active, .table td.hideLead > button:active {
          color: #fff !important;
          background: #48399e; }
        .table td.comment > button.comment-highlight, .table td.hideLead > button.comment-highlight {
          background: #48399e;
          color: #fff !important; }
    .table td .show-on-hover {
      opacity: .3;
      transition: opacity .8s ease; }
      .table td .show-on-hover:hover {
        opacity: 1; }
  .table thead tr {
    border-radius: 10px 10px 0 0; }
  .table thead th {
    padding: 15px 30px;
    vertical-align: middle;
    border-bottom: 1px solid #e0dfef;
    text-transform: uppercase;
    font-weight: 500;
    background: #48399e;
    color: #ffffff; }
  .table tbody tr:nth-of-type(odd) {
    background-color: transparent; }
  .table tbody tr:nth-of-type(even) {
    background-color: rgba(247, 247, 253, 0.8); }
  .table__checkbox {
    width: 50px;
    padding-right: 0 !important; }
  .table__sortable {
    color: #ffffff; }
    .table__sortable--active, .table__sortable:hover {
      color: white; }
  .table__not-sortable {
    color: whitey; }
    .table__not-sortable--active, .table__not-sortable:hover {
      color: #ffffff;
      text-decoration: none;
      cursor: default; }
  @media (max-width: 991.98px) {
    .table {
      display: block; }
      .table thead {
        display: none; }
      .table tbody {
        display: block; }
      .table tr {
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
      .table td {
        padding: 7.5px 15px; }
      .table .table__mobile-heading {
        width: 100%;
        padding-bottom: 0;
        display: flex;
        align-items: center; } }

.table-container {
  max-height: 500px;
  overflow-y: auto; }

.badge {
  background-color: #ededf4;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 30px;
  vertical-align: middle;
  text-transform: uppercase; }
  .badge--red {
    background-color: rgba(245, 36, 71, 0.1);
    color: #f52447; }
    .badge--red .badge__bullet {
      background-color: #f52447; }
  .badge__bullet {
    width: 4px;
    height: 4px;
    background-color: #676f73;
    border-radius: 50%;
    margin-right: 5px; }

.spinner {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.metric__stats {
  min-width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .metric__stats > .metric__title {
    font-size: 2em; }

.metric__icon-container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9em;
  opacity: .4; }
  .metric__icon-container > svg {
    vertical-align: 0; }

.metric__title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 400; }
  .metric__title--large {
    font-size: 1.5rem; }

.metric__description {
  color: #ffffff;
  font-size: .8em;
  line-height: 1.5em; }

.metric--fill {
  align-items: flex-start; }

.brand-metric {
  font-size: 1.5em; }
  .brand-metric .metric__title {
    font-size: 2.5rem; }
  .brand-metric .icon-aligner {
    display: inline-block; }
    .brand-metric .icon-aligner .icon-bubble {
      display: flex;
      background: #fff9;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      margin-bottom: 1em;
      font-size: 1.5em;
      justify-content: center;
      align-items: center; }
      .brand-metric .icon-aligner .icon-bubble .icon-wrapper {
        display: block; }

.step {
  display: flex; }
  .step__circle {
    width: 30px;
    height: 30px;
    background-color: #f7f7fd;
    border: 2px solid #e0dfef;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold; }

.insights__card {
  margin-bottom: 1em; }

.checkbox {
  width: 20px;
  height: 20px;
  position: relative; }
  .checkbox__inline {
    display: inline-block;
    margin: 0 .5em; }
  .checkbox span {
    width: 100%;
    height: 100%;
    border: 1px solid #e0dfef;
    background-color: #fff;
    display: block;
    pointer-events: none;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    border-radius: 4px; }
    .checkbox span i {
      opacity: 0; }
  .checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; }
    .checkbox input[type="checkbox"]:checked ~ span {
      border-color: #48399e;
      background-color: #48399e; }
      .checkbox input[type="checkbox"]:checked ~ span i {
        opacity: 1; }

.selection-bar {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: center; }
  .selection-bar__inner {
    display: inline-block;
    background-color: #48399e;
    padding: 10px 30px;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 60px;
    pointer-events: auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03); }
  .selection-bar__button {
    color: #fff;
    font-weight: 500; }
    .selection-bar__button:hover {
      color: #fff;
      text-decoration: none; }
  .selection-bar .separator {
    opacity: 0.2; }
  @media (max-width: 575.98px) {
    .selection-bar {
      bottom: 0; }
      .selection-bar__inner {
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      .selection-bar .separator {
        display: none; } }

.selection-bar-spacer {
  height: 60px; }

.article {
  height: 280px;
  background-size: cover;
  background-position: center;
  background-color: #676f73;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  align-items: flex-end;
  overflow: hidden; }
  .article:hover, .article:focus {
    text-decoration: none;
    opacity: 0.8;
    box-shadow: none; }
  .article:active {
    transform: translateY(2px); }
  .article__text {
    font-size: 20px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    color: #fff;
    width: 100%;
    padding: 30px;
    padding-right: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); }

.mobile-menu {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateY(-100%);
  z-index: 100;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  -webkit-overflow-scrolling: touch; }
  .mobile-menu__user {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #fff;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.03); }
  .mobile-menu__link {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    height: 50px;
    text-decoration: none;
    color: #676f73; }
    .mobile-menu__link:hover {
      text-decoration: none;
      color: #1b1d1e; }
    .mobile-menu__link .jewel {
      position: relative;
      font-size: .55em;
      right: 0;
      top: -1em; }
      .mobile-menu__link .jewel--text {
        line-height: 1em;
        color: #fff;
        padding: .4em .6em;
        border-radius: 1em;
        background-color: #48399e; }
    .mobile-menu__link .svg-inline--fa {
      font-size: 24px;
      margin-right: 15px; }
    .mobile-menu__link--active {
      color: #48399e; }
      .mobile-menu__link--active .svg-inline--fa {
        font-weight: 700; }
  .mobile-menu .dropdown {
    width: 100%; }

.mobile-menu-bar {
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  flex-direction: column;
  height: 65px;
  display: flex;
  justify-content: center;
  z-index: 101; }
  .mobile-menu-bar__button {
    background: transparent;
    border: none;
    font-size: 24px;
    padding: 10px; }

.mobile-menu-bar-spacer {
  height: 65px; }

.mobile-menu-main {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1); }

#close-menu-button {
  display: none; }

@media (max-width: 1199.98px) {
  .mobile-menu-visible {
    overflow: hidden; }
    .mobile-menu-visible #close-menu-button {
      display: block; }
    .mobile-menu-visible #open-menu-button {
      display: none; }
    .mobile-menu-visible .mobile-menu {
      transform: none;
      opacity: 1; }
    .mobile-menu-visible .mobile-menu-main {
      transform: translateY(100%);
      pointer-events: none;
      opacity: 0; } }

.side-scroll {
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.dropdown {
  position: relative;
  cursor: pointer; }
  .dropdown button.dropdown__trigger, .dropdown .dropdown__links button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer; }
  .dropdown button:focus {
    outline: 0; }
  .dropdown__popup {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 200px;
    text-align: left;
    top: 60px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(-10px) scale(0.9);
    transform-origin: top center;
    z-index: 1; }
    .dropdown__popup--tight {
      top: 45px; }
      .dropdown__popup--tight.dropdown__popup--above {
        bottom: 45px; }
    @media (max-width: 767.98px) {
      .dropdown__popup {
        left: 0 !important;
        right: 0 !important;
        width: 100% !important;
        margin-left: 0 !important; } }
    .dropdown__popup--large {
      width: 250px; }
      .dropdown__popup--large .dropdown__popup--align-center {
        margin-left: -125px; }
    .dropdown__popup--above {
      top: auto;
      bottom: 60px;
      transform: translateY(10px) scale(0.9);
      transform-origin: bottom center; }
      .dropdown__popup--above .dropdown__hatch {
        top: auto;
        bottom: -22px; }
    .dropdown__popup--align-right {
      right: 0; }
    .dropdown__popup--align-center {
      left: 50%;
      margin-left: -100px; }
    .dropdown__popup--align-left {
      left: 0; }
  .dropdown--active .dropdown__popup {
    opacity: 1;
    pointer-events: auto;
    overflow: visible;
    transform: none; }
  .dropdown--colored-options button {
    text-align: left;
    background: #48399e !important;
    margin-bottom: 0.3em;
    padding: .5em !important;
    line-height: 1.2;
    font-size: .9em;
    font-weight: normal;
    color: #fff;
    border-radius: 4px; }
  .dropdown__hatch {
    text-align: center;
    left: 0;
    right: 0;
    position: absolute;
    top: -16px; }
    .dropdown__hatch i {
      color: #fff;
      font-size: 26px; }
  .dropdown__links {
    padding: 15px 20px;
    max-height: 300px;
    overflow: auto; }

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .modal {
      padding: 0 !important;
      top: 65px;
      height: auto; }
      .modal .modal__content {
        border-radius: 0 !important;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%; } }
  .modal--active {
    opacity: 1;
    pointer-events: auto; }
    .modal--active .modal__content {
      transform: none;
      opacity: 1; }
  .modal--personal-data-form {
    z-index: 1001; }
  .modal__content {
    max-width: 500px;
    position: relative;
    overflow-y: auto;
    max-height: 90vh;
    transform: translateY(100px);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    opacity: 0; }
    .modal__content--large {
      max-width: 800px; }
    .modal__content--x-large {
      max-width: 960px; }
    .modal__content--form {
      min-height: 600px;
      max-width: 800px; }

.comments-modal {
  position: absolute;
  right: 70%;
  top: 50%;
  width: 25em;
  height: auto;
  background-color: #fff;
  padding: 30px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  text-align: left;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    .comments-modal {
      padding: 0 !important;
      top: 65px;
      height: auto; } }
  .comments-modal--active {
    opacity: 1;
    pointer-events: auto; }
    .comments-modal--active .modal__content {
      transform: none;
      opacity: 1; }

.comment__window .comment {
  display: block;
  width: 100%; }
  .comment__window .comment__text {
    display: flex; }
  .comment__window .comment__bubble {
    background: #f7f7fd;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    flex-grow: 1;
    margin-right: 1em;
    padding: .5em; }
  .comment__window .comment__footer {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    margin-bottom: 1em; }
    .comment__window .comment__footer .comment__comment-by {
      margin-right: 2.5em; }

.comment__list {
  opacity: 1;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1); }
  .comment__list--loading {
    opacity: .4; }

.comment__composer {
  display: flex;
  line-height: 1; }
  .comment__composer input {
    flex-grow: 1;
    margin-right: 1em;
    padding: .5em;
    border-radius: 5px;
    border: 1px solid #e0dfef; }

.field__label {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  display: block;
  margin-top: 30px;
  margin-bottom: 0; }

.field__input {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #e0dfef;
  color: #676f73;
  height: 40px;
  line-height: 40px;
  -webkit-appearance: none; }
  .field__input:focus {
    outline: none; }

.field__textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border: 2px solid #e0dfef;
  color: #676f73;
  padding: 10px;
  border-radius: 4px;
  height: 150px;
  -webkit-appearance: none; }
  .field__textarea:focus {
    outline: none; }

.tag {
  display: inline-block;
  border-radius: 30px;
  border: 1px solid #e0dfef;
  font-size: 0.875rem;
  padding: 3px 20px; }
  .tag--primary {
    border: none;
    background-color: rgba(72, 57, 158, 0.1);
    color: #48399e; }
  .tag--block {
    display: block; }

.filter__and {
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px; }
  .filter__and:before, .filter__and:after {
    content: "";
    display: block;
    height: 10px;
    width: 2px;
    background-color: #e0dfef;
    margin-left: 15px; }

.link-block {
  border: 1px solid #e0dfef;
  border-radius: 6px;
  padding: 10px;
  display: block; }
  .link-block:hover {
    text-decoration: none; }
  .link-block__icon {
    width: 42px;
    margin-right: 10px;
    text-align: center; }

.personal-data-form--loadin {
  opacity: .8; }

.personal-data-form .modification-field {
  padding: .5em;
  background: #efefef;
  border-radius: 5px;
  margin: 1em 0;
  position: relative; }
  .personal-data-form .modification-field input {
    margin: 0 .5em; }
  .personal-data-form .modification-field .remove-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: .5em; }

.mw-paragraph {
  max-width: 500px;
  color: #1b1d1e;
  line-height: 1.35em; }

.full-width {
  width: 100%; }

.rounded {
  border-radius: 10px;
  overflow: hidden; }

.text-large {
  font-size: 1.125rem; }

.text-extra-large {
  font-size: 1.25rem; }

.text-green {
  color: #28a745; }

.text-orange {
  color: #fd7e14; }

.text-primary-light {
  color: #7661ed; }

.text-accent {
  color: #f52447; }

.text-blue {
  color: #007bff; }

.text-black {
  color: #1b1d1e; }

.text-muted {
  color: #9eaab1; }

.font-weight-medium {
  font-weight: 500; }

.font-headline {
  font-family: "Poppins", sans-serif; }

.no-underline {
  text-decoration: none; }
  .no-underline:hover {
    text-decoration: none; }

.text-small {
  font-size: 0.875rem; }

.text-smaller {
  font-size: 0.75rem; }

.circle {
  border-radius: 50%;
  text-align: center;
  display: inline-block; }
  .circle--40 {
    width: 40px;
    height: 40px;
    line-height: 40px; }
  .circle--32 {
    width: 32px;
    height: 32px;
    line-height: 32px; }

.center-in-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.full-height-in-column {
  height: 100%;
  display: flex;
  flex-direction: column; }

.bg-light-green {
  background-color: rgba(40, 167, 69, 0.1); }

.bg-light-accent {
  background-color: rgba(245, 36, 71, 0.1); }

.bg-light-muted {
  background-color: rgba(158, 170, 177, 0.1); }

.bg-light-primary {
  background-color: rgba(72, 57, 158, 0.1); }

.active-in-list {
  background-color: rgba(72, 57, 158, 0.1);
  color: #48399e;
  margin-left: 10px;
  border-radius: 4px; }

.absolute-right {
  position: absolute;
  right: 0; }

.flex-grow {
  flex-grow: 1; }

.justify-content-right {
  justify-content: flex-end; }

.poppins {
  font-family: "Poppins", sans-serif; }

.roboto {
  font-family: "Roboto", sans-serif; }

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #676f73;
  background-color: #f7f7fd;
  width: 100%;
  height: 100%;
  position: fixed;
  line-height: 30px;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    html,
    body {
      font-size: 14px; } }

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
  color: #1b1d1e;
  font-weight: 400;
  line-height: 1.25em; }

h1 {
  font-size: 3rem;
  margin-bottom: 20px; }

h2 {
  font-size: 20px;
  font-weight: 500;
  color: #1b1d1e;
  margin-bottom: 15px; }

h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 7.5px; }

.container {
  padding-left: 30px;
  padding-right: 30px; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px; } }

.content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.wrapper {
  display: flex;
  height: 100%; }
  @media (max-width: 1199.98px) {
    .wrapper {
      flex-direction: column; } }

.small-gutters {
  margin-right: -7.5px;
  margin-left: -7.5px; }
  .small-gutters > .col,
  .small-gutters > [class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px; }

.smaller-gutters {
  margin-right: -5px;
  margin-left: -5px; }
  .smaller-gutters > .col,
  .smaller-gutters > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.h-spacing {
  width: 30px;
  flex-shrink: 0; }
  .h-spacing--medium {
    width: 15px; }
  .h-spacing--small {
    width: 10px; }
  .h-spacing--extra-small {
    width: 5px; }

.c-spacing {
  padding-left: 30px;
  padding-right: 30px; }
  .c-spacing--medium {
    padding-left: 20px;
    padding-right: 20px; }

.spacing {
  height: 30px; }
  .spacing--large {
    height: 45px; }
  .spacing--extra-large {
    height: 60px; }
  .spacing--medium {
    height: 20px; }
  .spacing--small {
    height: 15px; }
  .spacing--small-half {
    height: 7.5px; }
  .spacing--extra-small {
    height: 5px; }
