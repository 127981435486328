.tag {
  display: inline-block;
  border-radius: 30px;
  border: 1px solid $border;
  font-size: 0.875rem;
  padding: 3px 20px;

  &--primary {
    border: none;
    background-color: rgba($primary, 0.1);
    color: $primary;
  }

  &--block {
    display: block;
  }
}
