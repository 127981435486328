.badge {
  background-color: $gray;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 30px;
  vertical-align: middle;
  text-transform: uppercase;

  &--red {
    background-color: rgba($accent, 0.1);
    color: $accent;

    .badge__bullet {
      background-color: $accent;
    }
  }

  &__bullet {
    width: 4px;
    height: 4px;
    background-color: $textGray;
    border-radius: 50%;
    margin-right: 5px;
  }
}
