.comments-modal {
  position: absolute;
  right: 70%;
  top: 50%;
  width: 25em;
  height: auto;
  background-color: #fff;
  padding: 30px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  text-align: left;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  @include media-breakpoint-down(sm) {
    padding: 0 !important;
    top: 65px;
    height: auto;
  }

  &--active {
    opacity: 1;
    pointer-events: auto;

    .modal__content {
      transform: none;
      opacity: 1;
    }
  }
}

.comment {

  &__window {
    .comment {
      display: block;
      width: 100%;
      &__text {
        display: flex;
        >span {}
      }
      &__bubble {
        background: #f7f7fd;
        border-radius: 5px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        font-size: 12px;
        flex-grow: 1;
        margin-right: 1em;
        padding: .5em;
      }
      &__footer {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        margin-bottom: 1em;
        .comment__date {}
        .comment__comment-by {
          margin-right: 2.5em;
        }

      }
      ;
    }
  }
  &__list {
    opacity: 1;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    &--loading {
      opacity: .4;
    }
  }
  &__composer {
    display: flex;
    line-height: 1;
    input {
      flex-grow: 1;
      margin-right: 1em;
      padding: .5em;
      border-radius: 5px;
      border: 1px solid $border;
    }
    button {}
  }
}
