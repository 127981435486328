.step {
  display: flex;

  &__circle {
    width: 30px;
    height: 30px;
    background-color: $background;
    border: 2px solid $border;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}
.insights
{
  &__block{
    
  }
  &__card{
    margin-bottom:1em;
  }
  
}