.field {
  &__label {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    display: block;
    margin-top: 30px;
    margin-bottom: 0;
  }

  &__input {
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $border;
    color: $textGray;
    height: 40px;
    line-height: 40px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  &__textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border: 2px solid $border;
    color: $textGray;
    padding: 10px;
    border-radius: 4px;
    height: 150px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }
}
