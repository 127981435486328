.dropdown {
  position: relative;
  cursor: pointer;
  button.dropdown__trigger, .dropdown__links button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  button:focus {
    outline: 0;
  }
  &__popup {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 200px;
    text-align: left;
    top: 60px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    transform: translateY(-10px) scale(0.9);
    transform-origin: top center;
    z-index: 1;


    &--tight {
      top: 45px;

      &.dropdown__popup--above {
        bottom: 45px;
      }
    }

    @include media-breakpoint-down(sm) {
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
      margin-left: 0 !important;
    }

    &--large {
      width: 250px;

      .dropdown__popup--align-center {
        margin-left: -125px;
      }


    }

    &--above {
      top: auto;
      bottom: 60px;
      transform: translateY(10px) scale(0.9);
      transform-origin: bottom center;

      .dropdown__hatch {
        top: auto;
        bottom: -22px;
      }
    }

    &--align-right {
      right: 0;
    }

    &--align-center {
      left: 50%;
      margin-left: -100px;
    }

    &--align-left {
      left: 0;
    }
  }

  &--active {
    .dropdown__popup {
      opacity: 1;
      pointer-events: auto;
      overflow: visible;
      transform: none;
    }
  }
  &--colored-options {
    button {
      text-align: left;
      background: $primary !important;
      margin-bottom: 0.3em;
      padding: .5em !important;
      line-height: 1.2;
      font-size: .9em;
      font-weight: normal;
      color: #fff;
      border-radius: 4px;
    }
  }
  
  &__hatch {
    text-align: center;
    left: 0;
    right: 0;
    position: absolute;
    top: -16px;

    i {
      color: #fff;
      font-size: 26px;
    }
  }
  &__links {
    padding: $spacing / 2 $spacing / 1.5;
    max-height: 300px;
    overflow: auto;

  }
}
