.mobile-menu {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateY(-100%);
  z-index: 100;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  -webkit-overflow-scrolling: touch;

  &__user {
    padding-top: $spacing;
    padding-bottom: $spacing;
    background-color: #fff;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.03);
  }

  &__link {
    font-weight: 500;
    font-family: $fontHeadline;
    display: flex;
    align-items: center;
    height: 50px;
    text-decoration: none;
    color: $textGray;

    &:hover {
      text-decoration: none;
      color: $black;
    }

    .jewel {
      position: relative;
      font-size: .55em;
      right: 0;
      top: -1em;
      &--text {
        line-height: 1em;
        color: #fff;
        padding: .4em .6em;
        border-radius: 1em;
        background-color: $primary;
      }
    }

    .svg-inline--fa {
      font-size: 24px;
      margin-right: $spacing / 2;
    }

    &--active {
      color: $primary;

      .svg-inline--fa {
        font-weight: 700;
      }
    }
  }
  .dropdown {
    width: 100%;
  }
}

.mobile-menu-bar {
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  flex-direction: column;
  height: 65px;
  display: flex;
  justify-content: center;
  z-index: 101;

  &__button {
    background: transparent;
    border: none;
    font-size: 24px;
    padding: 10px;
  }
}

.mobile-menu-bar-spacer {
  height: 65px;
}

.mobile-menu-main {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#close-menu-button {
  display: none;
}

@include media-breakpoint-down(lg) {
  .mobile-menu-visible {
    overflow: hidden;

    #close-menu-button {
      display: block;
    }

    #open-menu-button {
      display: none;
    }

    .mobile-menu {
      transform: none;
      opacity: 1;
    }

    .mobile-menu-main {
      transform: translateY(100%);
      pointer-events: none;
      opacity: 0;
    }
  }
}
