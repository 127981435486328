.link-block {
  border: 1px solid $border;
  border-radius: 6px;
  padding: 10px;
  display: block;

  &:hover {
    text-decoration: none;
  }

  &__icon {
    width: 42px;
    margin-right: 10px;
    text-align: center;
  }
}