.toolbar {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__bar {
    background: $white;
    display: flex;
    min-height: 50px;
    border-radius: $radius;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
    align-items: center;
    padding-top: $spacing / 2.5;
    padding-bottom: $spacing / 2.5;
    margin-left: $spacing / 2.5;
  }
  &__date-picker {
    .react-datepicker {
      box-shadow: 2px 3px 7px 2px #ccc;
      &__input-container input {
        margin-left: $spacing / 2;
        text-align: center;
        border-radius: .5em;
        border: 2px solid #ddd;
      }
      &__input-container input:focus {
        outline: none;
        border-color: #aaa;
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range {
      background-color: $primaryLight;
    }
  }
  i {
    color: $black;
  }

  &__logo {
    max-height: 30px;
  }
}
