.article {
  height: 280px;
  background-size: cover;
  background-position: center;
  background-color: $textGray;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  align-items: flex-end;
  overflow: hidden;

  &:hover,
  &:focus {
    text-decoration: none;
    opacity: 0.8;
    box-shadow: none;
  }

  &:active {
    transform: translateY(2px);
  }

  &__text {
    font-size: 20px;
    font-weight: bold;
    font-family: $fontHeadline;
    color: #fff;
    width: 100%;
    padding: $spacing;
    padding-right: 50%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}
