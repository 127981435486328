@import "variables";
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400&display=swap');

$break: 960px;
$breakHeight: 1000px;

body.login {
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-weight: 300;
    background-image: url('/images/loginbg.jpg');
    background-size: cover;
    background-position: center;
    color: #000;
    
    .forgot-password-message{
        margin: 8em auto;
    }

    #root>div {
        height: 100%;
        display: flex;
    }
    .zmash-logo {
        display: block;
        fill: $primary;
        padding: 2em;
        position: fixed;
        right: 0;
        top: 0;
        width: 14em;
        @media screen and (max-width: $break) {
            display: block;
            position: relative;
            width: 60%;
            margin: 1em auto;
        }
    }
    button,
    .download-btn {
        background: $primary;
        border: none;
        border-radius: .5em;
        color: #fff;
        font-size: 1.75rem;
        font-weight: 300;
        opacity: 1;
        padding: .75em 0;
        transition: 100ms all ease;

        &:hover {
            opacity: .9;
            color: #fff;
        }
        &:active {
            opacity: .95;
            color: #fff;
            transform: scale(0.98);
        }
        &.reset-password{
            display: block;
            margin: 1em 5em;
        }

    }
    .login-form {
        background: #fff;
        box-shadow: 0em 0 1em .5em #00000033;
        flex: 1;
        height: 100%;
        min-width: 500px;
        max-width: 790px;
        overflow: hidden;
        text-align: center;
        @media screen and (max-width: $break) {
            width:100%;
            min-width: auto;
            max-width: none;
            font-size: .5em;
        }
        h1 {
            font-weight: 300;
            margin: 1em auto;
            font-size: 5em;
            position: relative;
            line-height:1;
    
            &::after {
                content: '';
                width: 2em;
                background: #48399e;
                height: .075em;
                display: block;
                margin: 0 auto;
                position: absolute;
                left: calc( 50% - 1em);
                bottom: -.3em;
            }
        }
        p.leading {
            font-size: 1rem;
        }


    }
    .form {
        padding: 2em 6em;
        margin: 1em auto;

        .error .row {
            font-size: 1rem;
        }

        .field {
            margin: 0 auto 4em auto;

            button {
                margin: 4em auto;
                width: 100%;
                
                @media screen and (max-width: $break) {
                    margin: 0 auto;
                }
                
                &:focus {
                    outline: none;
                    border: 2px solid $primary;
                }
            }

            input {
                border: 2px solid #fff;
                border-bottom: 2px solid $primary;
                font-size: 1.5rem;
                height: 3em;
                transition: 150ms border ease;
                width: 100%;
                padding: 0 1em;
                &:focus {
                    outline: none;
                    border: 2px solid $primary;
                }
            }
        }
    }
    .hero {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        text-align: center;
        h3,
        h2 {
            color: #000;
            font-weight: 300;
        }
        h2 {
            font-size: 2em;
        }
        .hero-card {
            background: #fff;
            box-shadow: .25em .25em .25em 0 #00000055;
            margin: 0 auto;
            padding: 2em 4em;
            margin: 0 1em;
            max-width: 40em;
            min-width: 20em;
        }
        .download-btn {
            width: auto;
            display: inline-block;
            margin-top: 1em;
            padding: .75em 2em;
        }
        @media screen and (max-width: $break) {
            display: none;
        }
    }

}

@media screen and (max-height: $breakHeight) {
    body.login .form .field {
        margin: 0 auto .5em auto;
    }

    body.login .form .field button {
        margin: 1em auto;
    }
}
