.personal-data-form {

  &--loadin{
    opacity:.8;
  }
  .modification-field {
    padding: .5em;
    background: #efefef;
    border-radius: 5px;
    margin: 1em 0;
    position: relative;
    input{
      margin:0 .5em;
    }
    
    .remove-btn{
      position: absolute;
      top: 0;
      right: 0;
      padding: .5em;
    }
  }
}
