.selection-bar {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: center;

  &__inner {
    display: inline-block;
    background-color: $primary;
    padding: $spacing/3 $spacing;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 60px;
    pointer-events: auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  }

  &__button {
    color: #fff;
    font-weight: 500;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .separator {
    opacity: 0.2;
  }

  @include media-breakpoint-down(xs) {
    bottom: 0;

    &__inner {
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .separator {
      display: none;
    }
  }
}

.selection-bar-spacer {
  height: 60px;
}
