.checkbox {
  width: 20px;
  height: 20px;
  position: relative;
  &__inline{
    display:inline-block;
    margin:0 .5em;
  }
  span {
    width: 100%;
    height: 100%;
    border: 1px solid $border;
    background-color: #fff;
    display: block;
    pointer-events: none;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;

    i {
      opacity: 0;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked ~ span {
      border-color: $primary;
      background-color: $primary;

      i {
        opacity: 1;
      }
    }
  }
}
