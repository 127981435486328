.filter {
  &__and {
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 10px;
      width: 2px;
      background-color: $border;
      margin-left: 15px;
    }
  }
}